import React, { useState } from "react";
import { Product } from "../../Services/productsData";
import {Dropdown} from "../../components/Dropdown/Dropdown";
import './ListCollectionComponent.css';

interface ListCollectionComponentProps {
    product: Product;
    setQuantity: (quantity: number) => void;
  }
  
  const ListCollectionComponent: React.FC<ListCollectionComponentProps> = ({
    product,
    setQuantity,
  }) => {
    const [quantity, setLocalQuantity] = useState<number>(1);
  
    const handleQuantityChange = (quantity: number) => {
      setLocalQuantity(quantity); 
      setQuantity(quantity); 
    };
  
    const truncateName = (name: string) => {
      return name.length > 10 ? `${name.slice(0, 10)}...` : name;
    };
  
    return (
      <div className="list-collection-container">
        <img src={product.imageUrl} alt={product.name} className="product-image fixed-size-image" />
        <div className="column-contenedor">
            <span className="product-name smalltext-header">{truncateName(product.name)}</span>
            <span className="product-size tinytext">Talla {product.size}</span>
        </div>
        <div className="Dropdwon">
            {/* Uso del Dropdown como selector de cantidad */}
            <Dropdown
             title=""
             elements={["1", "2", "3", "4"]}
             value={String(quantity)}
             setValue={(value) => handleQuantityChange(Number(value))} 
            />
        </div>
        <span className="product-price">{product.price * quantity} PEN</span>
      </div>
    );
  };
  
  export default ListCollectionComponent;