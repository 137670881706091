import React, { useEffect, useState } from "react";

/** importando estilos */
import './ModalLateralContainer.css';
import { ModalLateral } from "../ModalLateral/ModalLateral";
import { Button } from "../Button/Button";
import { QuotationContainerSharedInterface } from "../../interfaces/QuotationContainerSharedInterface";
import { ConverMoneyFormat } from "../../utils/ConvertDecimal";

interface ModalLateralContainerProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    quotationContainerShared: QuotationContainerSharedInterface;
    rateUpTo1CubicMeter: number;
    rateFor1To5CubicMeters: number;
    rateAbove5CubicMeters: number;
    handleReserveClick: () => void;
}

export const ModalLateralContainer: React.FC<ModalLateralContainerProps> = ({
    show,
    setShow,
    quotationContainerShared,
    rateUpTo1CubicMeter,
    rateFor1To5CubicMeters,
    rateAbove5CubicMeters,
    handleReserveClick
}) => { 

    return (
        <ModalLateral
            show={show}
            setShow={setShow}
            title="Detalles">
            <div className="modalLateralcontainer-body">
                <div className="modalLateralcontainer-first">
                    <div className="modalLateralcontainer-firt-column">
                    <div className="smalltext-header">Origen</div>
                    <div className="smalltext">{quotationContainerShared.origin}</div>
                    </div>
                    <div className="modalLateralcontainer-firt-column">
                    <div className="smalltext-header">Destino</div>
                    <div className="smalltext">{quotationContainerShared.destination}</div>
                    </div>
                    <div className="modalLateralcontainer-firt-column">
                    <div className="smalltext-header">Entrega en</div>
                    <div className="smalltext">{quotationContainerShared.deliveryAddress}</div>
                    </div>
                </div>

                <div className="modalLateralcontainer-first">
                    <div className="modalLateralcontainer-firt-row modalLateralcontainer-firt-title">
                        <div className="smalltext-header">SERVICIOS DE ORIGEN</div>
                        <div className="smalltext-header">USD por m3</div>
                    </div>
                    <div className="modalLateralcontainer-firt-row">
                        <div className="smalltext">De 0 a 1 m3</div>
                        <div className="smalltext">{ConverMoneyFormat(rateUpTo1CubicMeter.toString())}</div>
                    </div>
                    <div className="modalLateralcontainer-firt-row">
                        <div className="smalltext">Mayor a 1 hasta 5 m3</div>
                        <div className="smalltext">{ConverMoneyFormat(rateFor1To5CubicMeters.toString())}</div>
                    </div>
                    <div className="modalLateralcontainer-firt-row">
                        <div className="smalltext">Mayor a 1 hasta 5 m3</div>
                        <div className="smalltext">{ConverMoneyFormat(rateAbove5CubicMeters.toString())}</div>
                    </div>
                </div>

                <div className="modalLateralcontainer-first">
                    <div className="modalLateralcontainer-firt-row modalLateralcontainer-firt-title">
                        <div className="smalltext-header">SERVICIO DE TRANSPORTE ALL-IN</div>
                    </div>
                    <div className="modalLateralcontainer-firt-row">
                        <div className="smalltext">Total: {quotationContainerShared.cargoWeight} kg ; {quotationContainerShared.cargoVolume} m3</div>
                        <div className="smalltext">{quotationContainerShared.cargoVolume} m3 es igual a {quotationContainerShared.estimatedVolume} m3</div>
                    </div>
                </div>
            </div>

            <div className="modalLateralcontainer-bottom">
                <div className="modalLateralcontainer-firt-row modalLateralcontainer-firt-row_">
                    <div className="small-subheader">Total:</div>
                    <div className="modalLateralcontainer-firt-row modalLateralcontainer-firt-row_">
                        <div className="medium-header">{quotationContainerShared.price}</div>
                        <div className="smalltext">USD</div>
                    </div>
                </div>
                <Button 
                    color="blue-2"
                    content="Reservar"
                    onClick={()=>handleReserveClick()}
                />
            </div>
        </ModalLateral>
    )
}
