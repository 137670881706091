import React, { useEffect, useState } from "react";

/** importando componentes */

/** importando estilos */
import './ModalLateral.css';
import { Modal } from "../Modal/Modal/Modal";

interface ModalLateralProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    title?: any;
    children?: React.ReactNode;
}

export const ModalLateral: React.FC<ModalLateralProps> = ({
    show,
    setShow,
    children,
    title = '',
}) => { 

    // useEffect(()=> {
    //     show ? window.document.body.style.overflow = 'hidden' : window.document.body.style.overflow = 'unset';
    // }, [show])

    return (
        <>
            {show && 
            <div className="modalLateral">
                <Modal title={title} show={show} setShow={setShow}>
                    <div className="modalLateral-content">
                        {children}
                    </div>
                </Modal>
            </div>}
        </>
    )
}
