import React from "react";

// import css
import './ImportGroupComponent.css';

import { VerifyBlue } from "../../icons/solid/Verify_blue";
import { ProgressAnimation } from "../ProgressAnimation/ProgressAnimation";

interface ImportGroupComponentProps {
}


/**  component OrderMiniature 
    in this component the company will be rendered in card showing information about it*/
export const ImportGroupComponent: React.FC<ImportGroupComponentProps> = ({
    
    }) => {

    const progressPercentage = 75;

    return (
        <div 
            className="importGroup-main"
        >
              <div className="importGroup-img">
                    <img
                        src={"https://assets.adidas.com/images/w_600,f_auto,q_auto/aa53a0a800c846abb44aae8a00367e1d_9366/Zapatillas_Tensaur_Sport_Training_Cierre_de_Cordones_Blanco_GW6422_01_standard.jpg"} alt="importGroup" />
              </div>
              <div className="importGroup-body">
                <div className="smalltext"> Zapatillas Nike Jordan Air </div>
                <div className="paragraph-header">{"16" + " " + "PEN"}</div>
                <div className="smalltext importGroup-row">
                    <img src="https://storage-beta.arkabia.com/public/assets/images/png/calendar-outline.png" className="importGroup-icon-calendar"/>
                    { "Cierra en" + " 3 " + "días"}
                </div>
                <div className="smalltext importGroup-row">
                    <img src="https://storage-beta.arkabia.com/public/assets/images/png/box-outline.png" className="importGroup-icon-box" />
                    {"3 " + "cajas para completar"}
                </div>

                <ProgressAnimation progressPercentage={75}/>

                <div className="tinytext importGroup-badget">
                    EN TRÁNSITO 
                </div>

                <div className="importGroup-agency">
                    <img
                        className="importGroup-user" 
                        src="https://plus.unsplash.com/premium_photo-1683121366070-5ceb7e007a97?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"/>
                    <div className="tinytext-header">LAVANDA IMPORT</div>
                    <VerifyBlue/>
                </div>
              </div>
        </div>
    )
}