import React, { useEffect, useState } from "react";

import { useNavigate, useLocation, useParams } from "react-router-dom";

import ReactGA from "react-ga4";

// Importanto componente
import { ServicesOptions } from "../../../../components/Services/ServicesOptions/ServicesOptions";
import { CompanyList } from "../../../../components/CompanyList/CompanyList";
import { Button, Subheader } from "../../../../components";

import { useDispatch, useSelector } from "react-redux";

// Importando Slice
import { GetServicesByType } from "../../../../Slices/Service";
import { GetServiceTypeActive } from "../../../../Slices/ServiceType";
import { setHistoryLocation } from "../../../../Slices/HistoryLocation";

// Importando store
import type { AppDispatch } from "../../../../Store/Store";

import {
  PROVIDER,
  SERVICE,
  SERVICE_SELLER,
} from "../../../../Consts/BaseUrl";

// imoprtando interfaces
import { ServiceInterface } from "../../../../interfaces/Service";

// importando estilos
import "./MainServicePage.css";
import { GetCompanyById } from "../../../../Services/Company.service";

import { HeadPage } from "../../../../components/HeadPage/HeadPage";
import { FilterByRelevance } from "../../../../Consts/FilterRelevance";
// import { ModalAnimation } from "../../../../components/Modal/ModalAnimation/ModalAnimation";
import { ModalProcessService } from "../../../../components/Modal/ModalProcessService/ModalProcessService";
import { BackArrow } from "../../../../icons/outline/BackArrow";
import { SearchBlack } from "../../../../icons/outline/SearchBlack";
import { SearchTextField } from "../../../../components/SearchTextField/SearchTextField";  
import { FilteHorizontal } from "../../../../icons/outline/FilterHorizontal";
import { ModalFilter } from "../../../../components/Modal/ModalFilter/ModalFilter";
import { FilterService } from "../../../../components/FilterService/FilterService";
import { FilterCategory, FilterCountry } from "../../../../Consts/FilterCategory";
import { LoaderShimmerService } from "../../../../components/LoaderShimmerService/LoaderShimmerService";
import { SearchContainer } from "../../../../components/SearchContainer/SearchContainer";

import { TransportInterface } from "../../../../interfaces/TransportInterface";
import { QuotationTransportError } from "../../../../interfaces/QuotationTransportError";
import { CardContainer } from "../../../../components/CardContainer/CardContainer";
import { ModalLateralContainer } from "../../../../components/ModalLateralContainer/ModalLateralContainer";
import { getAllContainerSharedFromDate, searchContainerShared } from "../../../../Services/containerShared.service";
import { toast } from "react-toastify";
import { containerSharedInterface } from "../../../../interfaces/containerSharedInterface";
import { QuotationContainerSharedInterface } from "../../../../interfaces/QuotationContainerSharedInterface";
import { formatDistanceStrict, set } from "date-fns";
import { LinearLoader } from "../../../../components/LinearLoader/LinearLoader";
import { ModalProcessPay } from "../../../../components/Modal/ModalProcessPay/ModalProcessPay";
import { ModalCulqi } from "../../../../components/Modal/ModalCulqi/ModalCulqi";

interface MainServicePageProps {}

export const MainServicePage: React.FC<MainServicePageProps> = () => {
  let navigation = useNavigate();
  const params = useParams();
  let location = useLocation();

  const dispatch = useDispatch<AppDispatch>();

  // get variables from redux
  const { serviceList } = useSelector((state: any) => state.service);
  const { serviceTypeList } = useSelector((state: any) => state.serviceType);
  const { userDetail } = useSelector((state: any) => state.user);

  const [typeList, setTypeList] = useState<string[]>([]);
  const [resultServiceList, setResulServiceList] = useState<
    ServiceInterface[]
  >([]);

  const [showBack, setShowBack] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [showModalLateral, setShowModalLateral] = useState<boolean>(false);
  const [transport, setTransport] = useState<TransportInterface>({
    origin: undefined,
    destination: undefined,
    type: 'sea',
    lcl: {
      totalVolume: 0,
      totalWeight: 0,
      unitVolume: 'm³',
      unitWeight: 'kg',
      typeMeasure: 'volumen',
      packages: [],
      packingList: {
        name: '',
        url: ''
      },
      stackable: 'No puede ir nada encima',
      packingType: ''
    },
  });

  const [transportError, setTransportError] = useState<QuotationTransportError>({
    type: {
      status: false,
      message: ''
    },
    origin: {
      status: false,
      message: ''
    },
    destination: {
      status: false,
      message: ''
    },
    typeContainer: {
      status: false,
      message: ''
    },
    lcl: {
      cargoDescription: {
        status: false,
        message: ''
      },
      packages: {
        status: false,
        message: ''
      },
      totalVolume: {
        status: false,
        message: ''
      },
      totalWeight: {
        status: false,
        message: ''
      },
      unitVolume: {
        status: false,
        message: ''
      },
      unitWeight: {
        status: false,
        message: ''
      },
    }
  });
  const [isSearchContainer, setIsSearchContainer] = useState<boolean>(false);
  const [containerShared, setContainerShared] = useState<containerSharedInterface[]>([]);
  const [containerSelected, setContainerSelected] = useState<containerSharedInterface | undefined>(undefined);
  const [quotationContainerShared, setQuotationContainerShared] = useState<QuotationContainerSharedInterface | undefined>(undefined);

  const [showQuotationButton, setShowQuotationButton] =
    useState<boolean>(false);

  const [showModalPay, setShowModalPay] = useState<boolean>(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState<boolean>(false);

  const [serviceType, setServiceType] = useState('agenciamiento-de-aduana');

  const [showRequestQuote, setShowRequestQuote] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  // const [showDetail, setShowDetail] = useState<boolean>(true);
  const [activeSearch, setActiveSearch] = useState<boolean>(false);
  // const [showModalAnimation, setShowModalAnimation] = useState<boolean>(false);

  const [filterTitle, setFilterTitle] = useState<string>("Todos");

  // metodo para filtrar los servicios por relevancia
  const handleFilterRelevance = (relevance: string) => {
    setFilterTitle(relevance);
    switch (relevance) {
        case FilterByRelevance[0]:
          orderByRatingServices();
          break;
        case FilterByRelevance[1]:
          orderByReviewServices();
          break;
        case FilterByRelevance[2]:
          orderByTimeServices();
          break;
        case FilterByRelevance[3]:
          setResulServiceList(serviceList);
          break;
        default:
          setResulServiceList(serviceList);
          break;
    }
  }

// filtrar los servicios por rating
  const orderByRatingServices = () => {
    if(resultServiceList.length>0) {
        setResulServiceList([...serviceList].sort((a, b) => { return b.rating - a.rating}));
    }
  }

  const orderByReviewServices = () => {
    if(resultServiceList.length>0) {
      setResulServiceList([...serviceList].sort((a, b) => { return b.numComments - a.numComments}));
    }
  }

  const orderByTimeServices = () => {
      if(resultServiceList.length>0) {
        setResulServiceList([...serviceList].sort((a, b) => { return Number(a.createdAt) - Number(b.createdAt)}));
      }
  }

  // metodo para abrir detalle del servicio
  const handleClickService = (
    serviceId: string | undefined,
    serviceType: string
  ) => {
    if (
      serviceId &&
      serviceId.length > 0 &&
      serviceType &&
      serviceType.length > 0
    ) {
      GA4_select_item(serviceList.find((s: any) => s.id === serviceId));
      navigation(`/servicios/${serviceType}/${serviceId}`);
    }
  };

  /** SOLO FUNCIÓN DE SOLICITUD DE IMPORTACIÓN */

  // metodo para obtener los servicios activos
  const getServices = async (type: string) => {
    if (!loading) {
      setLoading(true);
      dispatch(GetServicesByType(type))
        .unwrap()
        .then((response) => {
          setResulServiceList(response);
          GA4_view_item_list(type, response)
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const getTypeServices = async () => {
    if (!loading) {
      setLoading(true);
      dispatch(GetServiceTypeActive())
        .unwrap()
        .then((response) => {
          setTypeList(response.map((r: any) => r.name));
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  //Buscar por titulo de las ordenes
  const searchOrderService = (text: string) => {
    if (text.length > 0) {
      setResulServiceList(
        serviceList.filter(
          (rqdl: any) =>
            rqdl.companyName.toUpperCase().includes(text.toUpperCase()) ||
            rqdl.title.toUpperCase().includes(text.toUpperCase())
        )
      );
    } else {
      setResulServiceList(serviceList);
    }
  };


  const handleWspClick = async (companyId: string) => {
    if (!loading) {
      try {
        setLoading(true);
        const company: any = await GetCompanyById(companyId);
        setLoading(false);
        window.open(
          company.whatsapp && company.whatsapp.length > 0
            ? "https://api.whatsapp.com/send/?phone=" +
                company.whatsapp +
                "&text=Hola! " +
                company.name +
                "! Quiero hablar con un asesor, vengo de Arkabia&type=phone_number&app_absent=0"
            : "",
          "_blank"
        );
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleTypeService = async (serviceTypeList:string) => {
    // setServiceType(serviceTypeList);
    handleFilterRelevance("Todos");
    navigation(`/${SERVICE}/${serviceTypeList}`)
  }

  // handle search
  const handleSearch = () => {
    setActiveSearch(true);
  }

  const handleModalRequestQuote = () => {
    let type = params.serviceType ? params.serviceType : 'agenciamiento-de-aduana';
    setServiceType(type);
    setShowRequestQuote(true);
  }

  const handleModalFilter = () => {
    setShowFilter(true);
  }

  const handleFilterBy = (filter: string) => {
    // let filter_split = filter.split(" ");
    if(filter === "Todos") {
      setResulServiceList(serviceList);
    } else {
      if(serviceType !== "busqueda-de-proveedor") {
        setResulServiceList(serviceList.filter((s: ServiceInterface) => s.keywords?.includes(filter)));
      }
    } 
  }

  //Container shared
  // method to search container
  const searchContainer = async (dateFrom: Date) => {
    if(!transport.origin || !transport.destination || loading) {
      return;
    }

    try {
      setLoading(true);
      setContainerSelected(undefined);
      setQuotationContainerShared(undefined);
      const response = await searchContainerShared(transport.type || "sea", transport.origin, transport.destination,
      transport.lcl?.totalWeight || 0, transport.lcl?.totalVolume || 0, dateFrom);
      console.log("🚀 ~ searchContainer ~ response:", response);
      setContainerShared(response);
      setIsSearchContainer(true);
      setLoading(false);
    } catch (error) {
      console.log("🚀 ~ searchContainer ~ error:", error);
      toast.error("Hubo un error al buscar los contenedores compartidos");
      setIsSearchContainer(false);
      setLoading(false);
    }
  }

  // method to get the container shared
  const getContainerShared = async () => {
    try {
      setLoading(true);
      const response = await getAllContainerSharedFromDate(new Date());
      // console.log("🚀 ~ getContainerShared ~ response:", response);
      setContainerShared(response);
      setIsSearchContainer(false);
      setQuotationContainerShared(undefined);
      setLoading(false);
    } catch (error) {
      console.log("🚀 ~ getContainerShared ~ error:", error)
      toast.error("Hubo un error al buscar los contenedores compartidos");
      setIsSearchContainer(false);
      setQuotationContainerShared(undefined);
      setLoading(false);
    }
  }

  // method to show message when user not search any container
  const showMessageSearchContainer = () => {
    toast.warn("Por favor, ingrese la información de origen, destino y carga para buscar contenedores compartidos", {autoClose: 5000});
  }

  // method to handle container shared click
  const handleClickContainerShared = (container: containerSharedInterface) => {
    console.log("🚀 ~ handleClickContainerShared ~ container:", container);
    setContainerSelected(container);
    setQuotationContainerShared({
      serviceId: container.serviceId,
      companyId: container.companyId,
      operationId: "",
      containerSharedId: container.id,
      cargoWeight: transport.lcl?.totalWeight || 0,
      cargoVolume: transport.lcl?.totalVolume || 0,
      estimatedVolume:  Math.ceil(transport.lcl?.totalVolume || 0),
      price: calculateTotalPrice(transport.lcl?.totalVolume || 0, container.rateUpTo1CubicMeter, container.rateFor1To5CubicMeters, container.rateAbove5CubicMeters),
      origin: (transport.origin?.country || "")+" "+(transport.type === "sea" ? transport.origin?.portIsoCode : transport.origin?.airport),
      destination: (transport.destination?.country || "")+" "+(transport.type === "sea" ? transport.destination?.portIsoCode : transport.destination?.airport),
      tranitTime: Number(formatDistanceStrict(container?.startDate.toDate(), container?.arrivalDate.toDate(), {unit: "day"}).split(" ")[0]),
      arrivaldate: container?.arrivalDate,
      deliveryAddress: container?.deliveryAddress || "",
      serviceType: serviceType,
      userId: userDetail.id,
      createdAt: new Date(),
      updatedAt: new Date()
    });
    setShowModalLateral(true);
  }

  // method to calculate the total price by volume
  const calculateTotalPrice = (volume: number, rateUpTo1CubicMeter: number, rateFor1To5CubicMeters: number, rateAbove5CubicMeters: number) => {
    let newVolume = Math.ceil(volume);
    if(volume <= 1) {
      return rateUpTo1CubicMeter*newVolume;
    } else if(volume > 1 && volume <= 5) {
      return rateFor1To5CubicMeters*newVolume;
    } else {
      return rateAbove5CubicMeters*newVolume;
    }
  }

  // methos to pay the container shared
  const handlePayContainerShared = () => {
    console.log("🚀 ~ handlePayContainerShared");
    setShowModalPay(true);
  }

  // metho to process the payment
  const processPayment = (token: string) => {
    console.log("🚀 ~ processPayment ~ token:", token)
    setIsProcessingPayment(true);
    toast.info("En construccion", {autoClose: 5000});
    setTimeout(() => {
      setIsProcessingPayment(false);
      setShowModalPay(false);
    }, 5000);
  }

  // Method to send data to GA4
  // Method to send view_item_list to GA4
  const GA4_view_item_list = (serviceType: string, services: ServiceInterface[]) => {
    // Send view_item_list to GA4
    ReactGA.gtag("event", "view_item_list", {
      item_list_id: "services_"+serviceType,
          item_list_name: "Services "+services[0].serviceTypeLabel,
          items: services.map((s) => ({
            item_id: s.id,
            item_name: s.title,
            item_brand: s.companyName,
            item_category: s.serviceTypeLabel,
            item_list_id: "services_"+serviceType,
            item_list_name: "Services "+s.serviceTypeLabel,
            quantity: 1
          }))
    });
  }

  // method to send the select_item to GA4
  const GA4_select_item = (service: ServiceInterface) => {
    ReactGA.gtag("event", "select_item", {
      item_list_id: "services_"+serviceType,
      item_list_name: "Services "+service.serviceTypeLabel,
      items: [{
        item_id: service.id,
        item_name: service.title,
        item_brand: service.companyName,
        item_category: service.serviceTypeLabel,
        item_list_id: "services_"+serviceType,
        item_list_name: "Services "+service.serviceTypeLabel,
        quantity: 1
      }]
    });
  }


  // useFect block
  // mostrar boton de regresar
  useEffect(() => {
    const searchParms = new URLSearchParams(location.search);
    if (searchParms.has("back") && searchParms.has("provider")) {
      const back = searchParms.get("back") === "true";
      const isProvider = searchParms.get("provider") === "true";
      setShowBack(back && isProvider);
    }
  }, [location.search]);

  //obtener servicios
  useEffect(() => {
    // getServiceKeyword();
    getTypeServices();
    // getHelpModalStatus();
    dispatch(setHistoryLocation(location.pathname));
  }, []);

  // carga quotation con datos del usuario
  useEffect(() => {
    if (userDetail && userDetail.id) {
      setShowQuotationButton(userDetail.userType.includes("user"));
    } else {
      setShowQuotationButton(true);
    }
  }, [userDetail]);

  useEffect(()=> {
    let type = params.serviceType ? params.serviceType : 'agenciamiento-de-aduana';
    setServiceType(type);
    if(type === "compartir-contenedor") {
      getContainerShared();
    } else {
      getServices(type);
    }
  }, [params.serviceType, serviceTypeList]);

  console.log(quotationContainerShared);

  return (
    <div className="mainService">
      <div className="mainService-container">
        <div className="mainService-header">
          {showBack && ( 
            <div className="mainService-provider">
              <HeadPage
                title={`${userDetail && userDetail.name ? "¡Hola "+userDetail.name+"!," : ""}`}
                subTitle={serviceTypeList.length> 0 ? serviceTypeList.find((s: any) => s.id === serviceType).description : ""}
              />
              <div className="order__nav">
                {/** Tab de ordenes y calendario */}
                <Subheader
                  content="Terceros"
                  active={true}
                  handleClick={() => {}}
                />

                <Subheader
                  content="Mis servicios"
                  active={false}
                  handleClick={() =>
                    navigation(`/${PROVIDER}/${SERVICE_SELLER}`)
                  }
                />
              </div>
              <div className="mainService-services">
            {/* end mobile section */}
            
          </div>
            </div>
          )}
            
          {!showBack &&
            <div className="mainService-provider">
              {serviceTypeList && (
                <ServicesOptions
                  servicesOptions={serviceTypeList}
                  ServicesOptionsDataDropdown={typeList}
                  setTextSearch={(e: any) => searchOrderService(e)}
                  serviceTypeActive={serviceType}
                  onClick={handleTypeService}
                  title='Buscar agencia'
                  userName={`${userDetail && userDetail.name ? "¡Hola "+userDetail.name+"!,"  : ""}`}
                />
              )}
              <HeadPage
                title={''}
                subTitle={serviceTypeList.length> 0 ? serviceTypeList.find((s: any) => s.id === serviceType).description : ""}
              />
            </div>
          }
        </div>

        <div className={resultServiceList.length>3 ?"mainService-body" : "mainService-body-min-3"}>
          <div className="mainService-company">

            {/*FILTROS QUE SOLO SE USAN SI SON DE TIPO "BUSQUEDA DE PROVEEDOR, AGENCIAMIENTO Y COURIER"*/}
            {(serviceType === "busqueda-de-proveedor" || 
              serviceType === "agenciamiento-de-aduana" ||
              serviceType === "courier"
            ) ? 
            <div className="mainService-main-content-filter"> 
              <div className="mainService-main-content-filters">
                <FilterService
                  title="Ordenar por"
                  titleDropdown="Todos"
                  filterItems= {FilterByRelevance}
                  handleItemSelected={handleFilterRelevance}
                />
                {serviceType && serviceType === "agenciamiento-de-aduana" && <FilterService
                  title={""}
                  titleDropdown={"Categoría"}
                  filterItems= {FilterCategory }
                  handleItemSelected={handleFilterBy}
                />}
                {serviceType && serviceType === "courier" && <FilterService
                  title={"Compra de" }
                  titleDropdown={ "Origen" }
                  filterItems= {FilterCountry}
                  handleItemSelected={handleFilterBy}
                />}
              </div>
              <div className={!activeSearch ? "mainservice-filters" : "mainservice-filters-expand"}>
                {/*<FilterSelectorMiniature preferences={[]} sortBy={[]} />
                */}
                
                {activeSearch && 
                    <BackArrow 
                        className="chat-search-content-icon-back" 
                        onClick={()=>setActiveSearch(false)}
                    />
                }
                {!activeSearch ? 
                    <SearchBlack className="chat-search-icon" onClick={handleSearch}/> :
                    <SearchTextField 
                        title={window.innerWidth <= 481 ? "Buscar": 'Buscar'}
                        setTextSearch={(e: any) => searchOrderService(e)}
                        darkIcon 
                    />
                }
                {!activeSearch ? 
                  <div className="mainservice-filter-icon" onClick={handleModalFilter}>
                    Filtros
                    <FilteHorizontal/>
                  </div> : 
                  null
                }
            </div>   

      
              {showQuotationButton && !activeSearch ? 
              <div className="companyList-requestQuoteButton">
                  <Button 
                    content={serviceType === "agenciamiento-de-aduana" || serviceType === "courier" ? 'Cotización múltiple' : "Comprar" }
                    color="black-75" size="normal" 
                    onClick={handleModalRequestQuote} />
              </div> : null}
            </div> :
            
            /*FILTRO QUE SOLO SE USA PARA EL SERVICIO CONTENEDOR COMPARTIDO"*/         
            <SearchContainer
              setTransport={setTransport}
              transport={transport}
              transportError={transportError}
              setTransportError={setTransportError}
              handleSearch={searchContainer}
            />
            }

            {(serviceType === "busqueda-de-proveedor" || 
              serviceType === "agenciamiento-de-aduana" ||
              serviceType === "courier"
            ) ? <>
              {!loading  ? (
                resultServiceList && resultServiceList.length > 0 ? (
                <CompanyList
                  serviceData={resultServiceList}
                  chooseAgency={false}
                  requestQuotes={[]}
                  onClickAddQuoteAgency={(e) => {
                    // console.log(e);
                  }}
                  onClickService={handleClickService}
                  onClickWsp={handleWspClick}
                  showButtonWsp
                />) :(
                  <div className="paragraph">No contamos el servicio disponible</div>
                )
              ) : (
                <div className="mainService-loader">
                  {Array.from({ length: window.innerWidth > 899 ? 9 : 4 }).map((_, index) => (
                    <LoaderShimmerService key={index} />
                  ))}
                </div>
              )}
              </> : !loading ?(
                containerShared.length > 0 ? containerShared.map((container, idx) => (
                  <CardContainer
                    key={idx}
                    container={container}
                    onClickButton={isSearchContainer ? handleClickContainerShared : ()=>showMessageSearchContainer()}
                  /> 
                )) :<div> No hay contenedores compartidos disponibles</div>
              ) : <LinearLoader />}
          </div>
        </div>
      </div>

      <ModalProcessService
        fromPage="mainService"
        serviceTypeId={serviceType}
        show={showRequestQuote}
        setShow={setShowRequestQuote}
      />

      <ModalFilter
        show={showFilter}
        setShow={setShowFilter}
        typeService={serviceType}
        handleFilterRelevance={handleFilterRelevance}
        FilterByRelevance={FilterByRelevance}
        FilterCategory={FilterCategory}
        handleFilter={handleFilterBy}
      />

      {showModalLateral && quotationContainerShared && containerSelected && <ModalLateralContainer
        setShow={setShowModalLateral}
        show={showModalLateral}
        quotationContainerShared={quotationContainerShared}
        rateAbove5CubicMeters={containerSelected.rateAbove5CubicMeters}
        rateFor1To5CubicMeters={containerSelected.rateFor1To5CubicMeters}
        rateUpTo1CubicMeter={containerSelected.rateUpTo1CubicMeter}
        handleReserveClick={handlePayContainerShared}
      />}

      {showModalPay && quotationContainerShared && <ModalCulqi
          showModal={showModalPay}
          setShowModal={setShowModalPay}
          price={quotationContainerShared?.price}
          email={userDetail.email}
          currency={"USD"}
          onClick={processPayment}
          loading={isProcessingPayment}
      />}
      

      {/* <ModalAnimation
        show={showModalAnimation}
        setShow={setShowModalAnimation}
        animation={[
          { 
            path: '/animations/Desktop/OrderFilter/data_ordenar_y_filtrar_1024.json',
            title: 'Ordenar y filtrar',
            image: STORAGE_ASSETS+'/images/png/orderfilter.png',
            description: 'Encuentra fácil a la agencia de aduana con más experiencia en tu industria.' 
          },
          { 
            path: '/animations/Desktop/ProfileMessage/data_perfil_y_mensaje_1024.json',
            title: 'Enviar mensaje',
            image: STORAGE_ASSETS+'/images/png/message.png',
            description: 'Conversa directamente con expertos sobre tu producto.' 
          },
          { 
            path: '/animations/Desktop/RequestQuote/data_solicitar_cotizacion_1024.json',
            title: 'Solicitar cotización',
            image: STORAGE_ASSETS+'/images/png/requestquote.png',
            description: 'Envíale tus requerimientos a distintas agencias y compara precios.' 
          }
        ]}
      /> */}
    </div>
  );
};
