import React, { Children, useEffect, useState } from "react";

/** Importando estilos */
import "./ContentListProduct.css";
import { CardContent } from "../CardContent/CardContent";
import { Button } from "../Button/Button";

interface ContentListProductProps {
    children?: React.ReactNode;
}

export const ContentListProduct: React.FC<ContentListProductProps> = ({
    children
}) => {
    
    return (
      <CardContent className="ContentListProduct">
        <div className="paragraph-header">Mi lista</div>
        {children}
        <div className="ContentListProduct-price">
            <div className="paragraph-header">Subtotal</div>
            <div className="paragraph-header">670 PEN</div>
        </div>

        <div className="ContentListProduct-footer">
            <div className="ContentListProduct-footer-btn"><Button content="Agregar a lista" color="white"/></div>
            <Button content="Ordenar"/>
        </div>
      </CardContent>
    );
};