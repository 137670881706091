import React, { useEffect, useRef, useState } from "react";

// importando estilos
import "./UserActive.css";
import { ImageFileValidation } from "../../Consts/FilesValidation";
import StoreService from "../../Services/Store.service";
import { UpdateUser } from "../../Services/User.service";
import { ErrorInterface } from "../../interfaces/ErrorInterface";

interface UserActiveProps {
  userId: string;
  userImg: string;

  isConnected?: boolean;
  name?: string;
  lastname?: string;
  loadUser?: ()=>void;
  white?:boolean;
  edit?: boolean;

  onChangePhoto?: (e:string)=>void;

  error?: ErrorInterface
  setError?: React.Dispatch<React.SetStateAction<ErrorInterface>>;
}

export const UserActive: React.FC<UserActiveProps> = ({
  userId,
  userImg,

  isConnected=false,
  name='',
  lastname='',
  loadUser=()=>{},
  white=false,
  edit,

  onChangePhoto=()=>{},

  error={status: false, message: ''},
  setError=()=>{},
}) => {

  const [firstLetterName, setFirstLetterName] = useState("");
  const [firstLetterLastName, setFirstLetterLastName] = useState("");
  const [isSvg, setIsSvg] = useState(false);
 
  const ref = useRef<HTMLInputElement>(null);

  const handleProfileClick = () => {
    ref.current?.click();
  }
 
  const onChangeImg = async (e:React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.files) {
      try {
        const responseFile = await StoreService.UploadFile(
          e.target.files[0], 
          userId+"/imgProfile/"+e.target.files[0].name,
          userId
        );
        const resUser = await UpdateUser({
          id: userId,
          photoUrl: responseFile,
          updatedAt: new Date(),
        });
        loadUser();
      } catch (error) {
        console.log("🚀 ~ file: UserActive.tsx:47 ~ onChangeImg ~ error:", error) 

      }
    }
  }

  const handleChangePhoto = async (e:React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.files) {
      try {
        const responseFile:any = await StoreService.UploadFile(
          e.target.files[0],
          "service/imgProfile/"+e.target.files[0].name,
          userId,
        );
        onChangePhoto(responseFile)
      } catch (error) {
        console.log("🚀 ~ file: UserActive.tsx:47 ~ onChangeImg ~ error:", error)
      }
    }
  }

  useEffect(() => {
    if(userImg && userImg.length>0) {
      const urlParts = userImg.split('?');
      const svg = urlParts[0].endsWith('.svg');
      setIsSvg(svg);
    }

  }, [userImg]);

  useEffect(() => {
    setFirstLetterName(name.charAt(0).toUpperCase());
    setFirstLetterLastName(lastname.charAt(0).toUpperCase());
  }, [name, lastname]);

  return (
    <div className={white?"useractive-white":"useractive"} onClick={edit?()=>handleProfileClick(): ()=>{}}>
      { userImg && userImg.length>0 ?
        isSvg ?
          <object data={userImg} style={{cursor: 'pointer'}}  type="image/svg+xml"></object> :
          <img src={userImg} alt={name} style={{cursor: 'pointer'}}/>
        : 
        <div className="paragraph-header useractive-text">
          {firstLetterName+''+firstLetterLastName}
        </div> 
      }

      {(error.status) && 
        <div className="textfield-error tinytext-header useractive-error">
          {error.message}
        </div>}
      
      <input 
        ref={ref} 
        id="photo-upload" 
        type="file" 
        accept={ImageFileValidation}  
        onChange={(e)=> userId && userId?.length>0 ? onChangeImg(e): handleChangePhoto(e)}/> 
      {isConnected && <span className="useractive-logged"></span>}
    </div>
  );
};
