import React from "react";
import './PorgressAnimation.css';

interface ProgressAnimationProps {
    progressPercentage: number;
}

export const ProgressAnimation: React.FC<ProgressAnimationProps> = ({
    progressPercentage
}) =>{

    return (
        <div className="progressAnimation">
            <div className="progressAnimation-bar" style={{ width: `${progressPercentage}%` }}>
                <div className="tinytext-header progressAnimation-text">{progressPercentage}%</div>
            </div>
        </div>
      );
};