import React, {useState, useEffect} from "react";

import { RatingSlider } from "../../RatingSlider/RatingSlider";
import { RowQuote } from "./RowQuote/RowQuote";
import { RowQuoteTaxes } from "./RowQuote/RowQuoteTaxes";
import { RowQuoteDetail } from "./RowQuote/RowQuoteDetail";
import { RowQuoteRestrictions } from "./RowQuote/RowQuoteRestrictions";
import { Quotation } from "../../ItemInfo/Quotation/Quotation";
import { Textarea } from "../../Textarea/Textarea";
import { Hover } from "../../Hover/Hover";

import { MedalRating } from "../../../icons/outline/MedalRating";

// import css
import './Quote.css';
import { Restrictions } from "../../../interfaces/Quotation";
import { ContainerType } from "../../../interfaces/ContainerType";
import { ConverMoneyFormat } from "../../../utils/ConvertDecimal";
import { Medal } from "../../../icons/solid/Medal";
import { Table } from "../../Table/Table";
import { Dropdown } from "../../Dropdown/Dropdown";

interface QuoterProps {
    edit: boolean;
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
    rating: number;
    countryOrigin: string;
    transportInternational: string;
    safe: string;
    expensesOrigin: string;
    subTotal1: string;
    subTotal2: string;
    destinationCountry: string;
    storage: string;
    // warehouseExpenses: string;
    storageExpenses: string;
    onCosts: string;
    operatingExpenses: string;
    administrativeExpensive: string;
    desadunaje: string;
    approval: string;
    localTransport: string;
    handling: string;
    // typeTransport: string;
    deconsolidation: string;
    otherExpenses: string;
    returnContain: string
    commissionCustoms: string;
    tax: string;
    total: string;
    toValue: string;
    igv: string;
    ipm: string;
    perception: string;
    isc: string;
    specificRight: string;
    antidumpi: string;
    surcharge: string;
    totalTaxes: string;
    transitTime: string;
    storageBase: string;
    expiration: string;
    restrictions: boolean;
    quotationObservation: string;
    setQuotationObservation: React.Dispatch<React.SetStateAction<string>>;
    typeContainer?: ContainerType | undefined 
    isInsurance?: boolean; // la cotizacion tiene seguro?
    aboutStay: string | undefined
    isFcl: boolean;
    impuesto?:boolean;
    fullfield?:boolean;
    isCourier?:boolean;
    isSharedContainer?:boolean;
    currency?:string;
}
export const Quote: React.FC<QuoterProps> = ({
    edit,
    setEdit,
    rating,
    countryOrigin,
    transportInternational,
    safe,
    expensesOrigin,
    subTotal1,
    subTotal2,
    destinationCountry,
    returnContain,
    storage,
    // warehouseExpenses,
    storageExpenses,
    onCosts,
    operatingExpenses,
    administrativeExpensive,
    desadunaje,
    approval,
    localTransport,
    handling,
    otherExpenses,
    // typeTransport,
    deconsolidation,
    commissionCustoms,
    tax,
    total,
    toValue,
    igv,
    ipm,
    perception,
    isc,
    specificRight,
    antidumpi,
    surcharge,
    totalTaxes,
    transitTime,
    storageBase,
    expiration,
    restrictions,
    quotationObservation,
    setQuotationObservation,
    typeContainer = {
        lcl: false,
        fcl: false,
        lclAir: false,
    },
    isInsurance=false,
    aboutStay='',
    isFcl,
    impuesto=false,
    fullfield=true,
    isCourier=false,
    isSharedContainer=false,
    currency="USD"
}) => {

    const [isHover, setIsHover] = useState(false);

    const handleMouseOver = () => {
        setIsHover(true);
    };
    
     const handleMouseOut = () => {
        setIsHover(false);
    };

    return (
        <div className="tableQuote">
             {/* <div className="tableQuote-currecy">
                <Dropdown 
                    title='Moneda'
                    elements={["USD", "EUR"]}
                    setValue={()=>{}}
                    value={currency || "USD"}    
                    disabled
                />
            </div> */}
            <div className='tableQuote-container box-shadow-card'> 
                <div className="paragraph-header tableQuote-title">{isSharedContainer ? "Servicio" : "Gastos de importación"}</div>
                <RowQuote
                    originCountry={countryOrigin}
                    open={true}
                    open2={true}
                    open3={true}
                    transportInternational={ConverMoneyFormat(transportInternational)}
                    safe={safe ? ConverMoneyFormat(safe): '0.00'}
                    expensesOrigin={ConverMoneyFormat(expensesOrigin)}
                    subTotal1={ConverMoneyFormat(subTotal1.toString())}
                    subTotal2={ConverMoneyFormat(subTotal2.toString())}
                    destinationCountry={destinationCountry}
                    storage={ConverMoneyFormat(storage)}
                    // warehouseExpenses={ConverMoneyFormat(warehouseExpenses)}
                    storageExpenses={ConverMoneyFormat(storageExpenses)}
                    onCosts={ConverMoneyFormat(onCosts)}
                    operatingExpenses={ConverMoneyFormat(operatingExpenses)}
                    administrativeExpensive={ConverMoneyFormat(administrativeExpensive)}
                    desadunaje={ConverMoneyFormat(desadunaje)}
                    approval={ConverMoneyFormat(approval)}
                    localTransport={ConverMoneyFormat(localTransport)}
                    handling={handling && handling.length>0 ? ConverMoneyFormat(handling): '0.00'}
                    // typeTransport={typeTransport}
                    deconsolidation={ConverMoneyFormat(deconsolidation)}
                    returnContain={ConverMoneyFormat(returnContain)}
                    commissionCustoms={ConverMoneyFormat(commissionCustoms)}
                    otherExpenses={ConverMoneyFormat(otherExpenses)}
                    tax={ConverMoneyFormat(tax)}
                    total={ConverMoneyFormat((Number(subTotal1)+Number(subTotal2)+Number(totalTaxes)).toString())}
                    typeContainter={typeContainer}
                    insurance={isInsurance}
                    fullfield={fullfield}
                    isCourier={isCourier}
                    isSharedContainer={isSharedContainer}
                    
                    /** Impuestos */
                    toValue={ConverMoneyFormat(toValue)}
                    igv={ConverMoneyFormat(igv)}
                    ipm={ConverMoneyFormat(ipm)}
                    perception={ConverMoneyFormat(perception)}
                    isc={ConverMoneyFormat(isc)}
                    specificRight={ConverMoneyFormat(specificRight)}
                    antidumpi={ConverMoneyFormat(antidumpi)}
                    surcharge={ConverMoneyFormat(surcharge)}
                    subTotal3={ConverMoneyFormat(totalTaxes.toString())}
                    impuesto={impuesto}
                />
            </div>

            {/* {!impuesto?<div className='tableQuote-container'> 
                <div className="paragraph-header tableQuotation-title">Impuestos</div>
                <RowQuoteTaxes
                    toValue={ConverMoneyFormat(toValue)}
                    igv={ConverMoneyFormat(igv)}
                    ipm={ConverMoneyFormat(ipm)}
                    perception={ConverMoneyFormat(perception)}
                    isc={ConverMoneyFormat(isc)}
                    specificRight={ConverMoneyFormat(specificRight)}
                    antidumpi={ConverMoneyFormat(antidumpi)}
                    surcharge={ConverMoneyFormat(surcharge)}
                    totalTaxes={ConverMoneyFormat(totalTaxes.toString())}
                    isCourier={isCourier}
                />
            </div>:''} */}

            {/** Ranting del aduanero */}
            {/* {edit && !impuesto ?<div className='tableQuote-container__'>
                <div className="tableQuote-rating__">
                    <div className="tableQuote-rating-content">
                        <div className="paragraph-header tableQuote-title__">Puntaje de servicio</div>
                        <Hover 
                            content="Este es el puntaje que le han dado los usuarios a la agencia de aduana"
                            show={isHover}
                            setShow={setIsHover}>
                            <MedalRating  
                                onMouseOver={handleMouseOver} 
                                onMouseOut={handleMouseOut}
                            />
                        </Hover>
                    </div>
                </div>
                <div className="tableQuote-rating">
                    <RatingSlider rating={rating}/>
                    <span className="paragraph tableQuote-title__">{Number((rating).toFixed(1))}</span>
                    <div className="tableQuote-rating-icon">
                        <MedalRating/>
                    </div>
                </div>
            </div>:'' } */}

            {!impuesto?<div className='tableQuote-container box-shadow-card'> 
                <div className="paragraph-header tableQuote-title">Detalles</div>
                <RowQuoteDetail
                    transitTime={transitTime}
                    storageBase={storageBase}
                    expiration={expiration}
                    isFcl={isFcl}
                    aboutStay={aboutStay}
                    isCourier={isCourier}
                    isSharedContainer={isSharedContainer}
                />
            </div>:''}
           
            <div className='tableQuote-container box-shadow-card'> 
                <div className="paragraph-header tableQuote-title">Restricciones</div>
                <div className="rowQuoteDetail-container">
                    <div className="rowQuoteDetail-body">
                        <div className="rowQuoteDetail__ rowQuoteDetail_transparent">
                            <div className="paragraph rowQuoteDetail_text" style={{color: "var(--gray-1)"}}>¿Requiere permisos?</div>
                            <div className="paragraph rowQuoteDetail_text__" style={{color: "var(--gray-1)"}}>{restrictions ? "Sí" : "No"}</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {restrictions && restrictions.length>0 && !impuesto?<div className='tableQuote-container'> 
                <div className="paragraph-header tableQuote-title">Restricciones</div>
                 <div className="tableQuote-container-restriction">
                    { 
                        restrictions.length>0 && restrictions.map((r, idx) => (
                            <Quotation
                                key={idx}
                                productNumber={idx}
                                products={restrictions}
                                setProductArr={()=>{}}
                                setProductDetails={()=>{}}
                                disabledFields={true}
                                disabledImages={true}
                                editRestrictions={false}
                                modalRQ
                            />
                        ))
                    }
                </div>
            </div>:''} */}

            {!impuesto?<div className='tableQuote-container box-shadow-card'> 
                <div className="paragraph-header tableQuote-title">{isCourier ? 'Observaciones' : 'Notas' }</div>
                <div className='tableQuote-body'>
                    <Textarea title="Inserta aquí tus notas"
                            value={quotationObservation}
                            onChange={(e:any) => setQuotationObservation(e.target.value)}
                            placeholder= " "
                            disable={edit}/>
                </div>
            </div>:''} 
        </div>
    )
}