import React from "react";

import './ButtonTypeTransport.css';
import { BtnsColorOptions } from "../../../@types/btnsColorTypes";
import { selectBtnBackgroundColor } from "../../../utils/selectButtonsStyles";

interface ButtonTypeTransportProps {
    icon: React.ReactNode;
    title: string // title of button
    isActive: boolean;
    onClick: () => void;
} 

export const ButtonTypeTransport: React.FC<ButtonTypeTransportProps> = ({
    icon,
    title,
    isActive,
    onClick=()=>{},
}) => {

    return (
        <div onClick={()=> onClick()} className={`buttonTypeTransport-container ${isActive ? "buttonTypeTransport-active":""}`}>
            {icon && <div className="buttonTypeTransport-icon">{icon}</div>}
            {title && <div className="smalltext-header buttonTypeTransport-text">{title}</div>}
        </div>
    )
}