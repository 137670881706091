import React, { useCallback, useEffect, useState } from "react";
import { Row } from "../Row/Row";
import { PortRow } from "./PortRow/PortRow";
import Geocode from "react-geocode";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { IShipment } from "../../interfaces/Shipment";

// importando iconos
import { Boat } from "../../icons/outline/Boat";
import {PlaneGray} from '../../icons/solid/PlaneGray';
import { Loader } from "../Loader/Loader";
import { COUNTRY_FLAG_BASE } from "../../Consts/BaseUrl";
import { PortInterface } from "../../interfaces/PortInterface";
import { set } from "date-fns";
import { AirportInterface } from "../../interfaces/AirportInterface";
import { Anchor } from "../../icons/outline/Anchor";
import { BuildingBlack } from "../../icons/solid/Building";

interface ShipmentPortsListProps {
  ports: PortInterface[]; // lista de puertos
  setOriginPort: (port: any) => void;
  airports: any[];
  typeSelected: string | undefined;
  setTextFieldName: React.Dispatch<React.SetStateAction<string>>;
  role:string;
  textFieldName: string; 
  isDestination?: boolean
  phase: "city" | "port";
  setPhase: React.Dispatch<React.SetStateAction<"city" | "port">>;
  setCitySelected: React.Dispatch<React.SetStateAction<PortInterface[]>>;
  setAirportSelected: React.Dispatch<React.SetStateAction<AirportInterface[]>>;
  onlyPort?: boolean;
}

const { REACT_APP_GOOGLE_GEOCODE_API_KEY, REACT_APP_GOOGLE_MAPS_API_KEY } =
  process.env;

Geocode.setApiKey(REACT_APP_GOOGLE_GEOCODE_API_KEY!);


export const ShipmentPortsList: React.FC<ShipmentPortsListProps> = ({
  ports,
  airports,
  setOriginPort,
  setTextFieldName,
  typeSelected, 
  role,
  textFieldName,
  phase="city",
  isDestination = false,
  setPhase,
  setCitySelected,
  setAirportSelected,
  onlyPort=false
}) => {
  
  const handleOriginPortSelected = (port: any) => {
    setOriginPort(port);
  };


  const handleItemClick = (item: any[]) => {   
    setPhase(phase === "city" ? "port" : "city"); 
   
    let tmpItems: any[] = [];
    item.forEach((element: any) => {
      (tmpItems.length === 0 || tmpItems.includes((i: any) => i.city !== element.city) ) && !isDestination &&
      !onlyPort && tmpItems.push({
        city: typeSelected === "sea" ? element.city : element.cityonly,
        country: typeSelected === "sea" ? element.country : element.country,
        type: 'city'
      });
      tmpItems.push({
        [typeSelected === "sea" ? "portIsoCode" : "alpha2Code"]: typeSelected === "sea" ? element.portIsoCode : element.cc,
        [typeSelected === "sea" ? "name" : "airport"]: typeSelected === "sea" ? element.name : element.displayname,
        city: typeSelected === "sea" ? element.city : element.cityonly,
        country: typeSelected === "sea" ? element.country : element.country,
        type: typeSelected === "sea" ? "PORT" : "AIRPORT"
      });
    });
    typeSelected === "sea" ?  setCitySelected(tmpItems) : setAirportSelected(tmpItems);
  }

  const handlePortClick = (port: any) => {
    handleOriginPortSelected(port);
    setTextFieldName(textFieldName);
  }


  return (
    <>
    { phase === "city" ? (
        <Row
          title={
            <PortRow
              icon={<img width={16} height={12} src={`${COUNTRY_FLAG_BASE}${typeSelected === "sea" ? (ports.length>0 ? ports[0].country.toLowerCase():"" ) : (airports.length>0 ? airports[0].cc.toLowerCase():"")}.svg`} alt="" />} 
              subTitle={typeSelected === "sea" ?  (ports.length>0? ports[0].country : "") : (airports.length>0 ? airports[0].country : "")}
              title={typeSelected === "sea" ? (ports.length>0 ? ports[0].city : "") : (airports.length>0? airports[0].cityonly :"")}
            />
          }
          onClick={()=> handleItemClick(typeSelected === "sea" ? ports : airports )}
          color="white-90"
          content="text"
          columValues={[]}
        />
      ) : (typeSelected === "sea" ? ports : airports).map((item, idx) => (
        <Row
          key={idx}
          title={
            <PortRow
              icon={item.type === "PORT"? <Anchor /> : item.type === "city" ? <BuildingBlack /> :   <PlaneGray />} 
              subTitle={item.type === "PORT" ? "Puerto": item.type === "city" ? "Ciudad": "Aeropuerto"}
              title={`${item.type === "PORT" ? item.city+" - "+item.portIsoCode: (item.type === "city" ? item.city :  item.airport)}`}
            />
          }
          onClick={()=> handlePortClick(item)}
          color="white-90"
          content="text"
          columValues={[]}
      />
      ))
    }
    </>
  );
};
