import React, { useEffect, useState } from "react";

// importando estilos
import "./SharedContainerDetailPage.css";
import { CardContent } from "../../../components/CardContent/CardContent";
import { ContentListProduct } from "../../../components/ContentListProduct/ContentListProduct";
import { ServiceDisplay } from "../../../components";
import { ServiceInterface } from "../../../interfaces/Service";

import ListCollectionComponent from "../../../components/ListCollectionComponentProps/ListCollectionComponentProps";
import { products as initialProducts } from "../../../Services/productsData";
import { Badge } from "../../../components/Badge/Badge";
import { ButtonBadget } from "../../../components/ButtonBadget/ButtonBadget";

interface SharedContainerDetailPageProps {}

export const SharedContainerDetailPage: React.FC<SharedContainerDetailPageProps> = () => {
  const [isSupplierSearch, setIsSupplierSearch] = useState<boolean>(false);
  const [serviceDetail, setServiceDetail] = useState<ServiceInterface>({
    id: '',
    active: false,
    basePrice: 0,
    city: '',
    companyId: '',
    companyName: '',
    country: {
      alpha2Code: '', 
      imgFlag: '',
      name: ''
    },
    createdAt: new Date(),
    description: '',
    faq: [],
    images: [],
    numComments: 0, 
    rating: 0,
    serviceType: '',
    serviceTypeLabel: '',
    status: 'published',
    title: '',
    updatedAt: new Date(),
    verify: false,
    numberVerify: 1
  });

  // Agregamos `id` a cada producto en el estado inicial
  const [productList, setProductList] = useState(
    initialProducts.map((product, index) => ({
      ...product,
      id: index + 1,  // Asignación única de `id`
    }))
  );

  const handleSetQuantity = (productId: number, quantity: number) => {
    console.log(`Producto ID: ${productId} - Nueva cantidad: ${quantity}`);
  };

  // Generamos 60 datos aleatorios para los participantes
  const participantsData = Array.from({ length: 60 }, (_, index) => ({
    id: index.toString(),
    userName: `Usuario ${index + 1}`,
    userLastName: `Apellido ${index + 1}`,
    profileUrl: `https://randomuser.me/api/portraits/men/${index}.jpg`,
    userActve: Math.random() > 0.5, 
    lastTime: new Date(),
    lastMessage: Math.random() > 0.5 ? "Primera importación" : "Importador frecuente",
    numNotificacion: Math.floor(Math.random() * 5), 
    onClick: (id: string) => console.log(`Chat ID clicked: ${id}`),
    isCompany: false,
    isActive: Math.random() > 0.5, 
  }));

  return (
    <div className="sharedContainerDetailPage">
        <div className="sharedContainerDetailPage-container">
            <CardContent className="sharedContainerDetailPage-left">
                <div className="sharedContainerDetailPage-left__img">
                  <ServiceDisplay
                    service={serviceDetail} 
                    onClickQuote={()=>(console.log('quote'))} 
                    onClickCompare={()=>(console.log('compare'))}
                    isSupplierSearch={isSupplierSearch}
                    showButton={true} 
                  />
                </div>
                <div className="sharedContainerDetailPage-left__detail">
                  <ButtonBadget name="TENDENCIA" handleBadget={()=>{}}/>
                </div>
            </CardContent>

            <div className="sharedContainerDetailPage-right">
              <ContentListProduct>
              <div className="sharedContainerDetailPage-right-scroll">
              {productList.map((product) => (
                  <ListCollectionComponent
                    key={product.id}  // Uso del `id` asignado
                    product={product}
                    setQuantity={(quantity: number) => handleSetQuantity(product.id, quantity)}
                  />
              ))}
              </div>
              </ContentListProduct>
              <CardContent>
                Participantes 60
              </CardContent>
            </div>
        </div>
    </div>
  );
};