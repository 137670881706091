import React, { useRef } from 'react';


import "./CardContainer.css";
import { ServiceInterface } from '../../interfaces/Service';
import { Rating } from '../Rating/Rating';
import { CommentCount } from '../CommentCount/CommentCount';
import { CityCountryDisplay } from '../CityCountryDisplay/CityCountryDisplay';
import { VerifyBlack } from '../../icons/solid/VerifyBlack';
import { VerifyBlue } from '../../icons/solid/Verify_blue';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { ProgressAnimation } from '../ProgressAnimation/ProgressAnimation';
import { containerSharedInterface } from '../../interfaces/containerSharedInterface';
import { FormatDate } from '../../utils/formatDate';

interface CardContainerProps {
    container: containerSharedInterface
    onClickButton: (container: containerSharedInterface) => void
};

/*
    Este componente muestra información clave de una importación 
    compartida, incluyendo la fecha de recogida, el lugar de origen y 
    los datos de otros usuarios que participan en la importación. 
    Permite a los usuarios visualizar y gestionar detalles de sus envíos de 
    manera clara y sencilla.
*/

export const CardContainer: React.FC<CardContainerProps> = ({
    container,
    onClickButton
}) => {

    // const { verify, numberVerify } = service;

    const verify = true;
    const numberVerify = 2;


    return (
    <div className='box-shadow-card cardContainer' onClick={() => window.innerWidth<600 ? onClickButton(container): undefined}>
        <div className='cardContainer-content-img-service'>
            <img 
                className='cardContainer-service-img'
                src={container.companyLogo}/>
        </div>
        <div className='cardContainer-detail'>
            <div className='cardContainer-fact-service'>
                <div className="cardContainer-fact-service-header">
                    <img 
                        className='cardContainer-service-img'
                        src={container.companyLogo}/>
                    <div className='paragraph-header cardContainer-fact-service-title'>
                        {container.companyName}
                    </div>
                    <div className="cardContainer-fact-service-check">
                        {verify && numberVerify <= 2 ? 
                        <VerifyBlack onClick={()=>{}}/> : 
                        <VerifyBlue onClick={()=>{}}/>}
                    </div>
                </div>
                <div className={"cardContainer-fact-service-detail"} >
                    <Rating rating={4.8} /> 
                    <CommentCount commentsCount={3} />
                    <CityCountryDisplay
                        city={""}
                        country={container.country.alpha2Code}
                        flagImg={container.country.imgFlag}
                    />
                </div>
            </div>

            <div className='cardContainer-row-1'>
                {/* <div className='cardContainer-badget'>
                    <div className='tinytext-header cardContainer-badget-content'>Más caro</div>
                </div> */}
                <div className='cardContainer-programation'>
                    <div className='cardContainer-programation-content'>
                        <div className='smalltext-header cardContainer-text-gray'>Recibe hasta</div>
                        <div className='smalltext-header'>{FormatDate(container.receiveDate.toDate())}</div>
                    </div>
                </div>

                <div className='cardContainer-programation'>
                    <div className='cardContainer-programation-content'>
                        <div className='smalltext-header cardContainer-text-gray'>Llega a Perú</div>
                        <div className='smalltext-header'>{FormatDate(container.arrivalDate.toDate())}</div>
                    </div>
                </div>

                <div className='cardContainer-programation'>
                    <div className='cardContainer-programation-content'>
                        <div className='smalltext-header cardContainer-text-gray'>Entrega en</div>
                        <div className='smalltext-header'>{container.deliveryCity}</div>
                    </div>
                </div>
            </div>

            <div className='cardContainer-progress'>
                <ProgressAnimation progressPercentage={container.usedCapacity/container.ratedCapacity}/>
            </div>

            <div className='cardContainer-row-2'>
                <div className='cardContainer-badget'>
                    <div className='tinytext-header cardContainer-badget-content'>Más caro</div>
                </div>
                <div className='cardContainer-button' onClick={() => window.innerWidth>=600 ? onClickButton(container): undefined} >
                    <div className='small-header-medium cardContainer-text-white'>$ {container.rateUpTo1CubicMeter}</div>
                    <div className='smalltext cardContainer-text-white'>Desde</div>
                </div>
            </div>
        </div>
    </div>
  );
};
