import React, { useEffect, useRef, useState } from "react";
import "./FilterService.css";
import { UpBlack } from "../../icons/solid/UpBlack";
import { TextField } from "../TextField/TextField";

interface FilterServiceProps {
    title: string;
    titleDropdown: string;
    filterItems: string[]; 
    handleItemSelected: (item: string) => void;
    iconDiferent?: React.ReactNode | any;
    showSearch?: boolean;
    setShowSearch?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FilterService: React.FC<FilterServiceProps> = ({
    title,
    titleDropdown = 'Todos',
    filterItems=[],
    handleItemSelected,
    iconDiferent = <UpBlack/>,
    showSearch = false,
    setShowSearch = () => { }
}) => {

    const ref = useRef<HTMLDivElement>(null);
    const selectRef = useRef<HTMLDivElement>(null);

    const [items, setItems] = useState<string[]>(filterItems);
    const [showFilterService, setShowFilterService] = useState(false);
    const [itemOneSelected, setItemOneSelected] = useState<string>(titleDropdown);
    const [maxHeight, setMaxHeight] = useState<number>(0);
    // const [showSearch, setShowSearch] = useState<boolean>(false);
    const [textSearch, setTextSearch] = useState<string>('');

    const handleShowFilterService = () => {
        setShowFilterService(!showFilterService);
        calculateMaxHeightList();
    }

    const handleShowFilterServiceItem = (item: string) => {
        setItemOneSelected(item === itemOneSelected ? titleDropdown: item);
        handleItemSelected(item === itemOneSelected ? 'Todos': item);
        setShowFilterService(false);
    }

    const calculateMaxHeightList = () => {
        const dropdownHeight = filterItems.length*40; // total height of the dropdown
        const windowHeight = window.innerHeight*0.90; //total height of the window
        const dropdownTop = Number(selectRef.current?.getBoundingClientRect().top); // distance from the bottom of the dropdown to the bottom of the window
        // console.log(dropdownHeight, windowHeight, dropdownTop)
        setMaxHeight(dropdownHeight < dropdownTop ? dropdownHeight : windowHeight - dropdownTop - 50);
        setShowSearch(dropdownHeight > dropdownTop);
    }

    const handleTextSearchChange = (text: string) => {
        setTextSearch(text);
        setItems(text.length> 0 ? filterItems.filter(f=>f.toLowerCase().includes(text.toLowerCase())) : filterItems);
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                // console.log("click")
                setTextSearch('');
                setShowFilterService(false);
                setItems(filterItems);
            }
          };
          document.addEventListener('click', handleClickOutside, true);
          return () => {
            document.removeEventListener('click', handleClickOutside, true);
          };
    }, []);


    return (
        <div role='filterService' className="filterService" ref={ref}>
            {title && 
            <div  className="smalltext-header">
                {title}
            </div>}
            <div className="filterService-dropdown-content">
                <div ref={selectRef} className="filterService-dropdown" onClick={()=>handleShowFilterService()}>
                    <div className="filterService-dropdown-header smalltext-header">
                        {itemOneSelected}
                    </div>
                    {iconDiferent}
                </div>
                {showFilterService && 
                    <div className="filterService--options" >
                        {showSearch && 
                            <div className="filterService--options-search" >
                                <TextField title="" placeholder="Buscar" value={textSearch} onChange={(e)=>handleTextSearchChange(e.target.value)}  />
                            </div>
                        }
                        <div className="filterService--options-items" style={{maxHeight: maxHeight}}>
                            { items.map((f, idx)=> (
                                <div 
                                    key={idx} 
                                    id="filterService--options-item"
                                    className={`filterService--options-item smalltext ${itemOneSelected === f ? 'filterService--options-background_selected' : 
                                        'filterSelectorExtended--options-background'}`}
                                    onClick={()=>handleShowFilterServiceItem(f)}>{f}</div>
                            ))}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};
