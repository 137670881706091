import React, {useEffect, useRef, useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { addWeeks, set } from "date-fns";
import { addMonths } from "date-fns/esm";

import { getAuth, onAuthStateChanged } from "firebase/auth";

// Componentes 
import { Button, Subheader } from "../../../components";
import { Calendar } from "../../../components/Calendar/Calendar";
import { EventView } from "../../../components/EventView/EventView";

import { SearchTextField } from "../../../components/SearchTextField/SearchTextField";
import { DashboardElements } from "../../../components/DashboardElements/DashboardElements";

import { CalendarDetail } from "../../../components/Calendar/Detail/CalendarDetail";
import { Option } from "../../../components/Calendar/Option/Option";
import { Today } from "../../../components/Calendar/Today/Today";
import { AbsoluteButton } from "../../../components/Button/Absolute/Absolute";
import { CalendarMonth } from "../../../components/Calendar/Month/CalendarMonth";
import { ModalAddEvent } from "../../../components/Modal/ModalAddEvent/ModalAddEvent";
import { ModalToken } from "../../../components/Tokens/ModalToken/ModalToken";
import { PaySuccessful } from "../../../components/PaySuccessful/PaySuccessful";
import { SaveDraft } from "../../../components/SaveDraft/SaveDraft";
import { Loader } from "../../../components/Loader/Loader";

import { GetLocalStorage, SetLocalStorage } from "../../../LocalStorange/LocalStorange";

import { useDispatch, useSelector } from "react-redux";

// Store
import type { AppDispatch } from "../../../Store/Store";


import { GetAllRequestQuoteDraft } from "../../../Services/RequestQuote.service";
import { GetUserByUID } from "../../../Slices/User";
import { GetServiceHover } from "../../../Slices/ServiceType";

// Importanto redirecciones 
import { SERVICE, OPERATIONS, QUOTATION, REQUEST_QUOTE, ORDER, HELP_MODAL_ORDERS, COMPARE } from "../../../Consts/BaseUrl";

// Importanto interfaces 
import {OperationInterface} from "../../../interfaces/Operation";
import {RequestQuoteInterface} from "../../../interfaces/RequestQuote";
import { Event } from "../../../interfaces/Event";

//Importando utilidades
import {FormatDate} from "../../../utils/formatDate";
import { SelectWeek } from "../../../utils/SelectWeek";
import { SelectMonth } from "../../../utils/SelectMonth";
import { CategoryEvent } from "../../../interfaces/Event";

// importando servicios
import { DeleteRequestQuote }from "../../../Services/RequestQuote.service";

import { getNumPages, GetOperationByUserIdRT, GetOperationByUserIdRTPagination } from "../../../Services/Operation.service";

//Importando data
import { UserList } from "../../../testdata/UserSearchList";

// Importando estilo 
import './Orders.css';

import { setHistoryLocation } from "../../../Slices/HistoryLocation";
import { toast } from "react-toastify";
import { Modal } from "../../../components/Modal/Modal/Modal";
// import { HeadPage } from "../../../components/HeadPage/HeadPage";
// import { ModalAnimation } from "../../../components/Modal/ModalAnimation/ModalAnimation";
import { FilterSelectorExtended } from "../../../components/FilterSelector/FilterSelectorExtended/FilterSelectorExtended";
import { FilterTypeOrder } from "../../../Consts/FilterTypeOrder";

import { ServiceTypeInterface } from "../../../interfaces/Service";
import { SearchBlack } from "../../../icons/outline/SearchBlack";
import { BackArrow } from "../../../icons/outline/BackArrow";
import { ModalProcessService } from "../../../components/Modal/ModalProcessService/ModalProcessService";
import { CardTypeService } from "../../../components/CardTypeService/CardTypeService";
import { DocumentSnapshot } from "firebase/firestore";
import InfiniteScroll from "react-infinite-scroll-component";
import { LinearLoader } from "../../../components/LinearLoader/LinearLoader";
import { EventSeccion } from "../../../components/EventSeccion/EventSeccion";
import { Search } from "../../../icons/outline/Search";
import { FilterService } from "../../../components/FilterService/FilterService";
import { FilteHorizontal } from "../../../icons/outline/FilterHorizontal";
import { QuoteIcon } from "../../../icons/outline/QuoteIcon";
import { LoaderShimmerOperation } from "../../../components/LoaderShimmerOperation/LoaderShimmerOperation";
import { CreateUserPlanResources, GetUserPlanResources } from "../../../Services/UserPlanResources.service";
import { UserPlanResoucesInterface } from "../../../interfaces/UserPlanResoucesInterface";
import { UpdateUser } from "../../../Services/User.service";

export const OrdersPage: React.FC<{}> = () => {
    let navigation = useNavigate();
    let location = useLocation();
 
    const dispatch = useDispatch<AppDispatch>();

    const {userDetail} = useSelector((state: any) => state.user);

    // useState user
    const [user, setUser] = useState(() => {
        const user = getAuth().currentUser;
        return user;
    });

    // state para manejar las aperturas de los miniature card de cotizacion
    const [openTabsDraft, setOpenTabsDraft] = useState<number>(0);
    const [openRequestquote, setOpenRequestquote] = useState<number>(0);


    const [showAddEvent, setShowAddEvent] = useState(false);
    const [showModalToken, setShowModalToken] = useState(false);
    const [showModalThanks, setShowModalThanks] = useState(false);

    const [activeSubMenu, setActiveSubmenu] = useState('orders'); // muestra section activo 

    const [reqDraft, setReqDraft] = useState<RequestQuoteInterface[] | undefined>();
    const [resultDraft, setResulDraft] = useState<RequestQuoteInterface[] | undefined>();

    const [operations, setOperations] = useState<OperationInterface[]| undefined>([]);
    const [resultOperation, setResulOperation] = useState<OperationInterface[]| undefined>([]);
    const [filterServiceLabel, setFilterServiceLabel] = useState<string>('');

    const [typeList, setTypeList] = useState<ServiceTypeInterface[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [showSearch, setShowSearch] = useState<boolean>(false);
    // QuotationDetail
    const [showQuotationModal, setShowQuotationModal] = useState<boolean>(false);
    // useState QuotationDetail
    const [requestQuote, setRequestQuote] = useState<RequestQuoteInterface>({
        serviceTypeId: 'agenciamiento-de-aduana',
        serviceTypeLabel: 'Agenciamiento de aduana',
        active: false,
        serviceId: '',
        status: "draft",
        title: '',
        user: {
            id: '',
            itNumber: 0,
            name: '',
            numOperations: 0,
            typeIt: '',
            phone: '',
            rating: 0,
        },
        country: {
            alpha2Code:'',
            imgFlag: '',
            city: ''
        },
        numServiceComments: 0,
        numServiceRating: 0,
        observation: '',
        transport: {
            type: 'sea',
            origin: {
              city: '',
              country: '',
            },
            destination: {
              city: '',
              country: '',
            },
            typeContainer: {
              lcl: false,
              fcl: false,
              lclAir: false
            }, 
        },
        customs: {
            coin: '',
            incoterm: '',
            insurance: undefined,
            regime: '',
            value: undefined,
            descriptionOperation: '',
        },
    });

    const [showSituationModal, setShowModalSituation] = useState<boolean>(false);
    const [activeSearch, setActiveSearch] = useState<boolean>(false);


    // let week = SelectWeek(selectedWeek)();
    // let month = SelectMonth(selectedDate)();

    // state nuevo evento 
    const [newEvent, setNewEvent] = useState<Event>({
        id: 0,
        detail: '',
        date: undefined,
        startHour: undefined,
        endHour: undefined,
        participants: [],
        categoryId: 0
    });
    //state nueva categoria de eventi
    const [newCategory, setNewCategory] = useState<CategoryEvent>({
        id: 0,
        title: '',
        color: '#FFFFFF',
        userId: 1
    });
 
    // pagination controller
    const [lastDoc, setLastDoc] = useState<DocumentSnapshot | undefined>(undefined);
    const [numPerPage, setNumPerPage] = useState<number>(8);

    // handle push router to service
    const handleClickService = () => {
       // navigation('/'+SERVICE);
        setShowModalSituation(true);
    }

    // handle push compare
    const handleClickCompare = (operationId: string | undefined) => {
        navigation('/'+OPERATIONS+'/'+operationId+"/"+COMPARE);
    }

    // handle push requestQuoteGenerate
    const handleClickQuoteGenerated = (operationId: string | undefined, requestQuoteId: string | undefined, quotationId: string | undefined) => {
        navigation('/'+OPERATIONS+'/'+operationId+'/'+requestQuoteId+'/'+QUOTATION+'/'+quotationId);
    }

    // handle push finish requestQuote
    const handleClickFinishQuote = (id: string | undefined) => {
        navigation('/'+OPERATIONS+'/'+REQUEST_QUOTE+'/'+id+'?sendOtherQuote=provider');
    }

    // hadle click order
    const handleClickOrder = (operationId: string | undefined, orderId: string | undefined, orderStep: string ) => {
        navigation('/'+OPERATIONS+'/'+operationId+'/'+ORDER+'/'+ orderId + '/' + (orderStep || 1));
    }

    // /** Siguiente semana */
    // const nextWeek = ()=> {
    //     setSelectedWeek(addWeeks(selectedWeek, 1));          
    // }

    // handle click previus week
    // const preWeek = ()=> {
    //     setSelectedWeek(addWeeks(selectedWeek, -1));         
    // }

    // /** Siguiente mes */
    // const nextMonth = ()=> {
    //     setSelectedDate(addMonths(selectedDate, 1));   
    // }

    // handle click previus month
    // const prevMonth = ()=> {
    //     setSelectedDate(addWeeks(selectedDate, -4)); 
       
    // }

    // handle click day in calendar
    // const handleCalendarDayClick = (day:Date)=> {
    //     setSelectedDay(day);
    //     setSelectedWeek(day);
    // }
   
    // handle click show modal thanks
    const handleClickModalThanks = () => {
        setShowModalThanks(true);
        setShowModalToken(false);
    }

    // handle click show modal tokens
    const handleBackModalToken = () => {
        setShowModalToken(true);
        setShowModalThanks(false);
    }

    // metodo para recuperar informacion del usuario
    const getUser = async (UID: string) => {       
        setLoading(true)
        dispatch(GetUserByUID(UID))
        .unwrap()
        .then((response) => {
            // getTotalNumoperation(response.id);
            getRequestQuoteDraft(response.id);
            getOperation(response.id);
            // setLoading(false);
        })
        .catch((error) => {
            console.log("🚀 ~ file: Orders.tsx ~ line 264 ~ getUser ~ error", error)
            setLoading(false);
        });
    }

   // lista de cotizaciones
   const getRequestQuoteDraft = async(userId: string) => {
        // setLoading(false);
        // if(!loading) {
            // setLoading(true);
            GetAllRequestQuoteDraft(userId, (response) => {
                setResulDraft(response);
                setReqDraft(response);
                if(response && response.length > 0 && response.length<4) {
                    setOpenTabsDraft(response.length);
                } else {
                    setOpenRequestquote(4);
                }
                // setLoading(false);
            }).catch((error) => {
                console.log("🚀 ~ file: Orders.tsx ~ line 280 ~ getRequestQuoteDraft ~ error", error)
                setLoading(false);
            });
            // dispatch(GetRequestQuoteDraftUser(userId))
            // .unwrap()
            // .then(async (response) => {
              
            // }
        // }
    }

    // const getTotalNumoperation = async (userId: string) => {
    //     const numRegisters = await getNumPages(userId);
    //     console.log("🚀 ~ getTotalNumoperation ~ numRegisters:", numRegisters);
    //     setNumRegister(numRegisters);
    // } 

   //Listando operaciones
    const getOperation = async (userId: string) => {
        try {       
            const {data, endDoc} = await  GetOperationByUserIdRTPagination(userId, lastDoc, numPerPage);
                setOperations((prev)=> prev ? [...prev, ...data] : data);
                setResulOperation((prev)=> prev ? [...prev, ...data] : data);
                setLastDoc(endDoc);
                // console.log("🚀 ~ file: Orders.tsx ~ line 309 ~ .then ~ response", response)
                if(data && data.length > 0 && data.length < 4) {
                    if(data.length <= (4-openTabsDraft)) {
                        setOpenRequestquote(data.length);
                    } else {
                        setOpenRequestquote((4-openTabsDraft));
                    }
                } else {
                    setOpenRequestquote(4-openTabsDraft);
                }
                setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
     
    }

    // mehotd to get type services
    const getTypeServices = async () => {
        dispatch(GetServiceHover())
        .unwrap()
        .then((response) => {
            setTypeList(response);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    };

    // handle search
    const handleSearch = () => {
        setActiveSearch(true);
    }

    const handleBackSearch = () => {
        setActiveSearch(false);
        setResulDraft(reqDraft);
        setResulOperation(operations);
    }

     // metodo para filtrar el tipo de orden
    const handleTypeOrder = (type: string) => {
        switch (type) {
            case FilterTypeOrder[0]:
                orderByRequestQuote();
                break;
            case FilterTypeOrder[1]:
                orderByOrder();
                break;
            default:
                setResulOperation(operations);
                break;
        }
    }

    // metodo para filtrar el servicio de una orden
    const handleFilterService = (service: string) => {
        if(service.length === 0) {
            setResulOperation(operations);
        } else {
            const serviceType = typeList.find((t) => t.name === service);
            if(serviceType) {
                setResulOperation(operations?.filter((o) => o.requestQuoteType === serviceType.id));
            }
        }   
        setFilterServiceLabel(service);
    }

    // filtar las solicitudes de cotizacion
    const orderByRequestQuote = () => {
        if(operations && operations.length>0) {
            setResulOperation(operations.filter((o:any) => o.status.requestQuote));   
        }
    }

    const orderByOrder = () => {
        if(operations && operations.length>0) {
            setResulOperation(operations.filter((o:any) => o.status.order));   
        }
    }

   // metodo para requestQuote
    const handleTrashClick = async (id:string) => {
        // console.log('eliminando', id)
       try {
        const response = await DeleteRequestQuote(id);
        if(response){
            getRequestQuoteDraft(userDetail.id);
        } else {
            toast.error('ocurrio un error al eliminar')
        }
       } catch (error) {
        //console.log("🚀 ~ file: Orders.tsx ~ line 375 ~ handleTrashClick ~ error", error)
        toast.error('ocurrio un error al eliminar')
       }
        
    }

    // metodos requestQuote
    // metodo para recuperar requestQuote
    const GetRequestQuoteDetail = async (requestQuoteId: string) => {
        // setLoading(true);
        if(resultDraft) {
            try {
                const request: any = resultDraft.find((item: any) => item.id === requestQuoteId);
                setRequestQuote(request);
                setShowQuotationModal(true);
                // setLoading(false);
            } catch (error) {
                //console.log("🚀 ~ file: Orders.tsx ~ line 341 ~ GetRequestQuoteDetail ~ error", error)
                setLoading(false);
            }
        }
    }

    // show modal for first time
    // const getHelpModalStatus = () => {
    //     const activeHelpModal = GetLocalStorage(HELP_MODAL_ORDERS);
    //     setShowModalAnimation(!activeHelpModal);
    //     SetLocalStorage(HELP_MODAL_ORDERS, true);
    // }

    //Buscar por titulo de las ordenes
    const searchOrder = (text: string) => {
        if(reqDraft === undefined || operations === undefined){
            return;
        }
        if(text.length>0) {
            setResulDraft(reqDraft.filter((rqdl:any) => (rqdl.title.toUpperCase()).includes(text.toUpperCase()) || (rqdl.companyName.toUpperCase()).includes(text.toUpperCase())));
            setResulOperation(operations.filter((rqdl:any) => (rqdl.title.toUpperCase()).includes(text.toUpperCase()) || ((rqdl.requestQuotes.length>0 ? rqdl.requestQuotes[0].companyName : (rqdl.companyName || "") ).toUpperCase().includes(text.toUpperCase()))));
        } else {
            setResulDraft(reqDraft);
            setResulOperation(operations);
        }
    }


    // method to determine loader to infinite scroll
    const getLoaderInfinitiveScroll = () => {
        if(resultOperation?.length === 1) {
            return null;
        } else {
            if(resultOperation && resultOperation.length > 0 && resultDraft && resultDraft.length > 0) {
                return <LinearLoader/>
            }
        }
    }


    // se ejecuta al cargar el componente
    useEffect (() => {
        getTypeServices();
        // getHelpModalStatus();
        const auth = getAuth();
        onAuthStateChanged(auth, (userCurrent) => {
        if(userCurrent) {
            getUser(userCurrent.uid);
            setUser(userCurrent);            
        } 
        });
        dispatch(setHistoryLocation(location.pathname));  
    }, []);

    return (
        <div className="ordersPage">
            <div className="ordersPage-header">
                <Subheader content="Operaciones" active={activeSubMenu === 'orders'} 
                            handleClick={()=>{ setActiveSubmenu('orders');}} />
                        
                <Subheader content="Eventos" active={activeSubMenu !== 'orders'} 
                            handleClick={()=>{ setActiveSubmenu('events');}} />
            </div>
            <div className="ordersPage-container">
                {(activeSubMenu === 'orders' || window.innerWidth >= 901) && 
                <div className="ordersPage-left">
                    <div className="ordersPage-left-action">
                        <div className="ordersPage-left-action-first">
                            <div className="ordersPage-left-action-first-left">
                                <div className="ordersPage-left-action-first-row">
                                    <QuoteIcon/>
                                    <div className="smalltext-header">Cotizaciones</div>
                                </div>
                                <div className="smalltext ordersPage-left-action-first-row">
                                    <span className="smalltext-header">0</span>
                                    de
                                    <span className="smalltext-header">3</span>
                                    cotizaciones disponibles
                                </div>
                                <div className="smalltext-header ordersPage-left-action-first-text-blue">¿Más cotizaciones?</div>
                            </div>
                            <div className="ordersPage-left-action-first-right">
                                <Button content="Iniciar operación" onClick={()=>handleClickService()}/>
                            </div>
                        </div> 
                        <div className="ordersPage-left-action-second">
                            <div className="ordersPage-left-action-left">
                                <div className="ordersPage-left-action-left_one">
                                    <>{activeSearch && <BackArrow 
                                        className="ordersPage-left-action-left-icon-back" 
                                        onClick={()=>handleBackSearch()}
                                    />}
                                    {!activeSearch ? <Search className="ordersPage-left-action-left-icon" onClick={handleSearch}/> :
                                        <SearchTextField 
                                            title={window.innerWidth <= 481 ? "Buscar": 'Buscar'}
                                            setTextSearch={(e:any)=> searchOrder(e)}
                                            darkIcon 
                                        />
                                    }</> 
                                    {!activeSearch && <FilterService
                                        title="Ordenar por"
                                        titleDropdown="Todos"
                                        filterItems= {FilterTypeOrder}
                                        handleItemSelected={handleTypeOrder}
                                    />}
                                </div>
                                {!activeSearch && <FilterService
                                    title=""
                                    titleDropdown="Filtros"
                                    filterItems= {typeList.map((t) => t.name)}
                                    handleItemSelected={handleFilterService}
                                    iconDiferent={<FilteHorizontal/>}
                                    setShowSearch={setShowSearch}
                                    showSearch={showSearch}
                                />}
                            </div>
                            <div className="ordersPage-left-action-right">
                                <Button content="Iniciar operación" onClick={()=>handleClickService()}/>
                            </div>
                        </div>
                    </div>

                    
                    { !loading  ? 
                        <div id="ordersPage-dashboard" className="ordersPage-left-operation ordersPage-dashboard">
                            <InfiniteScroll
                                dataLength={resultOperation && resultDraft ? 
                                    resultOperation.length+resultDraft.length: 0}
                                next={()=>getOperation(userDetail.id)}
                                hasMore={true} // Replace with a condition based on your data source
                                loader={getLoaderInfinitiveScroll()}
                                endMessage="No hay mas operaciones"
                                scrollableTarget="ordersPage-dashboard"
                            >
                                <div className="ordersPage-left-operation-scroll">
                                    {resultDraft && resultDraft.length>0 && 
                                    resultDraft.map((rqdl:RequestQuoteInterface, idx:number) => (
                                        <SaveDraft key={idx}
                                                handleClickDraft={()=>GetRequestQuoteDetail(rqdl.id?rqdl.id:'')}
                                                handleClickTrash={()=>handleTrashClick(rqdl.id?rqdl.id:'')}
                                                importation={rqdl.title}
                                                category={rqdl.category}
                                                companyName={rqdl.companyName}
                                                date={FormatDate(rqdl.createdAt ? rqdl.createdAt.toDate():new Date())}
                                                    open={idx<=openTabsDraft}
                                        />
                                    ))}

                                    {resultOperation && resultOperation.length>0  &&
                                    resultOperation.map((o:OperationInterface, idx:number) => (
                                        <DashboardElements
                                            key={idx}
                                            id={o.id?o.id : ''}
                                            operationId={o.id || ''}
                                            numOperation={o.numOperation.toString()}
                                            importationTitle={o.title}
                                            type={o.status.requestQuote ? "requestQuote" : "order"}
                                            dataImportation={o.status.requestQuote ? o.requestQuotes : o.orders}
                                            dateOperation={FormatDate(o.createdAt ? o.createdAt.toDate():new Date())}
                                            isOpen={idx<=openRequestquote}
                                            isCourier={o.requestQuoteType === 'courier'}
                                            currencyOperation={o.currency}
                                            handleClickCompare={handleClickCompare}
                                            handleClickFinishQuote={handleClickFinishQuote}
                                            handkeClickItemData={ o.status.requestQuote ? handleClickQuoteGenerated : handleClickOrder}
                                        />                                            
                                    ))}
                                </div>
                            </InfiniteScroll>
                        </div>
                        :     
                        <div className="loading-screen ordersPage-left-operation">
                        {Array.from({ length: window.innerWidth > 899 ? 6 : 4 }).map((_, index) => (
                            <LoaderShimmerOperation key={index} />
                        ))}
                    </div>      
                    } 
                    
                </div>}
                {(activeSubMenu === 'events' || window.innerWidth >= 901) && userDetail.authStep === 4 &&
                <div className="ordersPage-right">
                    <EventSeccion/>
                </div>}
            </div>

            {showAddEvent && <ModalAddEvent 
                userList ={UserList}
                show={showAddEvent} 
                setShow={setShowAddEvent}
                newCategory={newCategory}
                setNewCategory={setNewCategory}
                newEvent={newEvent}
                setNewEvent={setNewEvent}
            />}

            {showModalToken && <ModalToken 
                show={showModalToken}
                setShow={setShowModalToken}
                handleClickModalThanks={handleClickModalThanks}
            />}

            {showModalThanks&&<PaySuccessful 
                show={showModalThanks}
                setShow={setShowModalThanks}
                companyName="Arkabia"
                amount={5.00}
                handleBack={handleBackModalToken}
            />}
            {/* {showQuotationModal && <ModalQuote            
                requestQuote={requestQuote}
                setRequestQuote={setRequestQuote}
                show={showQuotationModal}
                setShow={setShowQuotationModal}
                fromPage="chat"
            />} */}

            {showQuotationModal && <ModalProcessService
                fromPage="orderUser"
                requestQuote={requestQuote}
                serviceTypeId={requestQuote.serviceTypeId || ""}
                show={showQuotationModal}
                setShow={setShowQuotationModal}
                companyId={requestQuote.companyId || ""}
            />}

            {/* <ModalAnimation
                show={showModalAnimation}
                setShow={setShowModalAnimation}
                animation={[
                { 
                    path: '/animations/Desktop/OrderService/data_contratar_servicio_1024.json',
                    title: 'Reservar servicio',
                    image: {STORAGE_ASSETS+'/images/png/orderservice.png'},
                    description: 'Acepta una cotización e inicia tu importación.' 
                }
                ]}
            />    */}

            {/* {userDetail && userDetail.userType && 
            <Modal disableToast title="¡Tu solicitud fue enviada!" show={showThanks} setShow={setShowThanks}  >
                <div className="modalThanks-container">
                    <div className="modalThanks-container-row">
                    <img src={STORAGE_ASSETS+"/images/png/check_yellow.png"} alt="" />
                    <div className="paragraph-header service-colorText">
                        {"Gracias por utilizar Arkabia, la agencia te responderá en breves."}
                    </div>
                    </div> 
                <div className="paragraph-header service-colorText">
                    ¿Deseas cotizar con más agencias?
                </div>
                <div className="modalThanks-container-row">
                    <div className="modalThanks-container--buttons">
                    <Button 
                        content="Si"
                        color="green-2"
                        onClick={()=>{setShowThanks(false); navigation(`/${OPERATIONS}/${REQUEST_QUOTE}/${redirectOperationId}?sendOtherQuote=provider`)}}
                    />
                    <Button 
                        content="Volver"
                        color="black-25"
                        onClick={()=>{setShowThanks(false); cleanFields();}}
                    /> 
                    <Button 
                        content="No"
                        onClick={()=>{setShowThanks(false); navigation('/'+OPERATIONS)}}
                    />
                    </div>
                </div>
                </div>
            </Modal>} */}
            {showSituationModal && <Modal className="order-modalSiatuation" show={showSituationModal} setShow={setShowModalSituation}>
                <CardTypeService 
                onClose={()=>setShowModalSituation(false)}
            />
            </Modal>}
        </div>
        )   
    }