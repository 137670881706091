import React, { useEffect, useState } from "react";

/** importando componentes */
import { Checkbox } from "../Checkbox/Checkbox";

/** importando iconos */
import { InfoCircle } from "../../icons/outline/InfoCircle";

/** importando estilos */
import './Insurance.css';
import { Hover } from "../Hover/Hover";
import { InfoCircleWhite } from "../../icons/outline/InfoCircle_white";
import { InfoCircleGray } from "../../icons/outline/InfoCircleGray";
import { Shield } from "../../icons/solid/Shield";
import { set } from "date-fns";
import { ErrorInterface } from "../../interfaces/ErrorInterface";

interface InsuranceProps {
    title?: string
    secure: boolean | undefined;
    setSecure: React.Dispatch<React.SetStateAction<boolean>>;
    resume?: boolean;
    error?: ErrorInterface
    showCheckNo?: boolean;
}

export const Insurance: React.FC<InsuranceProps> = ({
    title = '¿Deseas seguro?',
    secure,
    setSecure,
    resume=false,
    error = {
        status: false,
        message: false,
    },
    showCheckNo = false
}) => {

    const ref = React.useRef<HTMLDivElement>(null);

    const [isHover, setIsHover] = useState<boolean>(false);
    const [cancelMouseOut, setCancelMouseOut] = useState<boolean>(false);


    const handleMouseOver = () => {
        setIsHover(true);
    };
    
     const handleMouseOut = () => {
        setIsHover(false);
    };

    const handleClick = () => {
        setCancelMouseOut(true);
        setIsHover(true);
    }

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
          if(cancelMouseOut && ref.current && !ref.current.contains(e.target)) {
            setCancelMouseOut(false);
            setIsHover(false);
          }
        }
    
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    
      }, [cancelMouseOut])

    return (
        <div ref={ref} className={`insurance-container`} role='insurance'>
            <div className="insurance-content">
                <div className="insurance-question">
                    <Shield/>
                    <div className="paragraph-header insurance-colorText">{title}</div> 
                </div>

                <div className="insurance-col">
                    <div className="insurance-check">
                        <div className={`paragraph-header`}>Si</div>
                        <Checkbox 
                            checked={secure===true} 
                            setChecked={()=>setSecure(!secure)} 
                        />
                        {/* <div className={`paragraph-header `}>{window.innerWidth<=600 ? 'Si' : 'Sí, deseo' }</div> */}
                        
                        {/* <Checkbox
                            checked={secure===false}
                            setChecked={()=>{setSecure(false)}} 
                        />
                        <div className={`paragraph-header `}>{window.innerWidth<=600 ? 'No' : 'No, gracias' }</div> */}
                    </div>
                    {showCheckNo && <div className="insurance-check">
                        <div className={`paragraph-header`}>No</div>
                        <Checkbox 
                            checked={secure===false} 
                            setChecked={()=>setSecure(!secure)} 
                        />
                    </div>}
                    {error.status && <div className="tinytext text-error"> {error.message} </div>}
                </div>
            </div>

            <div className="insurance-info">
                {!resume && 
                <Hover
                    title="¿Qué es seguro?"
                    content="Es una garantía de indemnización para tus mercancías en caso ocurra algún siniestro durante su transporte internacional."
                    show={isHover}
                    setShow={setIsHover}
                >
                <InfoCircleGray 
                        className="insurance--icon"
                        onMouseOver={handleMouseOver} 
                        onMouseOut={!cancelMouseOut ? handleMouseOut : ()=>{}}
                        onClick={()=>window.innerHeight>720 ? handleClick() : {}}
                    />
                </Hover>}  
            </div>
        </div>
    )
}