import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type SearchWhiteProps = React.HTMLAttributes<HTMLDivElement> & {};

export const SearchWhite: React.FC<SearchWhiteProps> = ({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+'/images/png/searchWhite.png'}  alt=""  />
        </div>
    )
}