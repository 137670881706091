import { UMUnitMobileType } from "../@types/UMUnit";
import { UMVolumeMobile } from "../@types/UMVolume";

export const ConvertToMeters = (measure: number, umWeight: UMUnitMobileType | undefined): number => {
    switch (umWeight) {
        case "m":
            return measure;
        case "cm":
            return measure*0.01;
        case "ft":
            return Number((measure * 0.3048).toFixed(2));
        case "in":
            return Number((measure * 0.0254).toFixed(2));
        default:
            return measure;
    }
}

export const ConvertToMetersCubic = (measure: number, umWeight: UMVolumeMobile | undefined): number => {
    switch (umWeight) {
        case "m³":
            return measure;
        // case "cm3":
        //     return measure*0.000001;
        case "ft³":
            return Number((measure * 0.0283168).toFixed(2));
        // case "in3":
        //     return Number((measure * 0.0000163871).toFixed(2));
        default:
            return measure;
    }
}
