
export const PhasesImportData: string[] = [
    "Cotización",
    "Consignatario y agente de carga",
    // "Agente de carga y partida",
    "Documentación",
    "Carga enviada",
    "Traducción de la factura",
    "Arribo y canal de control",
    "Facturación y reseña",
]

export const PhasesSearchProviderData: string[] = [
    "Requerimiento",
    "Entrega de orden",
    "Facturación y reseña"
]

export const PhasesImportDataMobile: string[] = [
    "Orden",
    "Consignatario y agente",
    // "Agente de carga y partida",
    "Documentación",
    "Carga enviada",
    "Traducción de factura",
    "Arribo y canal de control",
    "Facturación y reseña",
]

export const PhasesImportDataHome: string[] = [
    "Orden",
    "Documentación",
    "Carga enviada",
    "Facturación y reseña",
]

export const PhasesSharedContainerData: string[] = [
    "Cotización",
    "Agente de carga",
    "Facturación y reseña",
]

export const phaseTypeService = {
    agenciamiento: PhasesImportData,
    courier: PhasesImportData,
    busquedaProveedor: PhasesSearchProviderData,
    permisosRestricciones: PhasesImportData,
    traducciónIntepretación: PhasesImportData,
    sharedContainer: PhasesSharedContainerData,
}