import React, { useState } from 'react';

import './SelectCard.css';
// import { SelectService } from '../../testdata/SelectService';
import { CheckCircle } from '../CheckCircle/CheckCircle';
import { SituationOptionsInterface } from '../../interfaces/SituationOptionsInterface';
import { STORAGE_ASSETS } from '../../Consts/BaseUrl';

interface SelectCardProps {
    data: SituationOptionsInterface[];
    setTitle: React.Dispatch<React.SetStateAction<string>>;
}

export const SelectCard: React.FC<SelectCardProps> = ({
    data,
    setTitle
}) => {

    const [ check, setCheck] = useState<string>('');

    const handleCheck = (value: string, navigation: string) => {
        setCheck(value);
        setTitle(navigation);
    }



    return (
        <div className='selectCard'>
            {data.map((s, idx) => (
                <div className='selectCard-container' key={idx} onClick={()=>handleCheck(s.title, s.navigation)}> 
                    {/* <div className='selectCard-header-img'>
                        <img src={s.urlImg} alt={s.title} />
                    </div>   */}
                    <div className='selectCard-header-title'>
                        {/* <CheckCircle
                            checked={check===s.title} 
                            setChecked={()=>handleCheck(s.title, s.navigation)}
                        /> */}
                        {check===s.title ?
                        <div className='selectCard-check-active' onClick={()=>handleCheck(s.title, s.navigation)}>
                            <img src={STORAGE_ASSETS+'/images/png/check-blue.png'}/>
                        </div> :
                        <div className='selectCard-check'></div>}

                        <div className='selectCard-Title tinytext'>
                            {s.title}
                        </div> 
                    </div>
                </div>
            ))}
        </div> 
        
    )
}