import { collection, getDocs, orderBy, query, Timestamp, where } from "firebase/firestore";
import { DB } from "../firebaseConfig/FirebaseConfig";
import { IShipment } from "../interfaces/Shipment";
import { containerSharedDBInterface, containerSharedInterface } from "../interfaces/containerSharedInterface";
import { GetServiceDetail } from "./Service.service";

export const searchContainerShared = async (typeTransport: string, origin: IShipment, destination: IShipment,
    totalWeight: number, totalVolume: number, dateFrom: Date) : Promise<containerSharedInterface[]> => {
    
    const dateFromTimestamp = Timestamp.fromDate(dateFrom);
    const q = query(collection(DB, "containerShared"), where("status", "==", true), where("isFullContainer", "==", false),
    where("typeTransport", "==", typeTransport), where("origin", "==", origin), where("destination", "==", destination),
    where("receiveDate", ">=", dateFromTimestamp));
    const response = await getDocs(q);
    let data: containerSharedInterface[] = [];
    
    if (!response.empty) {
        const containers = response.docs.map(doc => ({id: doc.id, ...doc.data()} as containerSharedDBInterface));
        
        const filteredContainers = containers.filter(container => container.usedCapacity + totalVolume <= container.maxLoadCapacity);
        
        const containerDetailsPromises = filteredContainers.map(async (container) => {
            const service = await GetServiceDetail(container.serviceId);
            return {
                ...container,
                companyLogo: service.companyLogo || '',
                companyName: service.companyName,
                country: service.country,
                numComments: service.numComments,
                rating: service.rating
            };
        });
        
        data = await Promise.all(containerDetailsPromises);
    }
    
    return data;
};

export const getAllContainerSharedFromDate = async (dateFrom: Date) : Promise<containerSharedInterface[]> => {
    const dateFromTimestamp = Timestamp.fromDate(dateFrom);
    const q = query(collection(DB, "containerShared"), where("status", "==", true), where("isFullContainer", "==", false),
    where("receiveDate", ">=", dateFromTimestamp), orderBy("receiveDate", "asc"));
    const response = await getDocs(q);
    let data: containerSharedInterface[] = [];
    
    if (!response.empty) {
        const containers = response.docs.map(doc => ({id: doc.id, ...doc.data()} as containerSharedDBInterface));
        
        const containerDetailsPromises = containers.map(async (container) => {
            const service = await GetServiceDetail(container.serviceId);
            return {
                ...container,
                companyLogo: service.companyLogo || '',
                companyName: service.companyName,
                country: service.country,
                numComments: service.numComments,
                rating: service.rating
            };
        });
        
        data = await Promise.all(containerDetailsPromises);
    }
    
    return data;
}