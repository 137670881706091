export interface Product {
    name: string;
    size: number;
    price: number;
    currency: string;
    imageUrl: string;
  }
  
  export const products: Product[] = [
    {
      name: "Zapatillas Nike Jordan",
      size: 40,
      price: 120,
      currency: "PEN",
      imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSu533MXLaPFunkRGcpwYMgO7pVefR8bQh_Og&s",
    },
    {
      name: "Zapatillas Nike Jordan",
      size: 40,
      price: 220,
      currency: "PEN",
      imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwEG1UP9K04dvxyVTcfAsmS7_DUkoQPPxLjA&s",
    },
    {
      name: "Zapatillas Nike Jordan",
      size: 42,
      price: 330,
      currency: "PEN",
      imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwEG1UP9K04dvxyVTcfAsmS7_DUkoQPPxLjA&s",
    },
  ];
